



































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/models/user';
import { parse, ParseResult, unparse } from 'papaparse';
import {
  EligibleParticipant,
  EpUploadDto,
  EpParsingErrorResult,
  RowParsingError,
  Series,
  UploadHistory,
  ExportInfo,
} from '@/models';
import RebuildLeaderboard from '@/components/RebuildLeaderboard.vue';
import moment from 'moment';
import * as xlsx from 'xlsx';

@Component({
  components: { RebuildLeaderboard },
})
export default class EligibleParticipants extends Vue {
  epFile: File | null = null;
  epsExport: ExportInfo | null = null;
  uploading = false;
  failedParse: EpParsingErrorResult = { headerError: false, duplicates: [], rows: [] };

  dialog = false;
  dobMenu = false;
  startDate = false;
  endDate = false;
  search = '';
  dialogDelete = false;
  loading = false;

  genders = ['Male', 'Female', 'Non-binary'];
  headers = [
    { text: 'First Name', value: 'firstName' },
    { text: 'Last Name', value: 'lastName' },
    { text: 'Club', value: 'club' },
    { text: 'Gender', value: 'gender' },
    { text: 'Date of Birth', value: 'dateOfBirth' },
    { text: 'Active - Start Date', value: 'startDate' },
    { text: 'Active - End Date', value: 'endDate' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  orgId = '';
  eps: EligibleParticipant[] = [];
  uploadHistory: UploadHistory | null | undefined = null;
  series: Series | null = null;
  editedIndex = -1;

  editedItem: EligibleParticipant = {
    firstName: 'John',
    lastName: 'Smith',
    club: '',
    gender: 'Male',
    dateOfBirth: new Date().toISOString().substr(0, 10),
    startDate: '',
    endDate: '',
  };

  defaultItem: EligibleParticipant = {
    firstName: 'John',
    lastName: 'Smith',
    club: '',
    gender: 'Male',
    dateOfBirth: new Date('1970-2-20').toISOString().substr(0, 10),
    startDate: '',
    endDate: '',
  };

  get formTitle() {
    return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
  }

  seriesDetails(seriesId: string): Series | null {
    return this.$store.getters['series/seriesDetails'](seriesId);
  }

  created() {
    this.initialize();
  }

  async initialize() {
    this.loading = true;
    if (this.$store.state.organizations.organizations == null) {
      await this.$store.dispatch('organizations/getOrganizations');
    }

    this.orgId = this.$store.getters['organizations/getOrgIdFromSlug'](
      this.$route.params.organizationSlug
    );

    if (this.orgId) {
      await this.$store.dispatch('series/getSeriesList', this.orgId);
      await this.$store.dispatch('eligibleParticipants/getEligibleParticipantsList', {
        organizationId: this.orgId,
        seriesId: this.$route.params.seriesSlug,
      });

      this.eps = await this.$store.getters['eligibleParticipants/eligibleParticipants'](
        this.orgId,
        this.$route.params.seriesSlug
      );

      this.series = this.seriesDetails(this.$route.params.seriesSlug);
      this.uploadHistory = this.$store.getters['series/seriesEpUploadHistory'](
        this.$route.params.seriesSlug
      );
    }

    if (this.eps.length) {
      this.epsExport = {
        url: URL.createObjectURL(
          new Blob(
            [
              unparse(this.eps, {
                columns: [
                  'id',
                  'firstName',
                  'lastName',
                  'club',
                  'gender',
                  'dateOfBirth',
                  'startDate',
                  'endDate',
                ],
              }),
            ],
            { type: 'text/csv' }
          )
        ),
        filename: `eligibleParticipants-${
          this.$route.params.seriesSlug
        }-${new Date().toISOString().substr(0, 10)}`,
      };
    }

    this.loading = false;
  }

  editItem(item: EligibleParticipant) {
    if (this.eps) {
      this.editedIndex = this.eps.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    }
  }

  deleteItem(item: EligibleParticipant) {
    if (this.eps) {
      this.editedIndex = this.eps.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    }
  }

  async deleteItemConfirm() {
    if (this.eps) {
      await this.$store.dispatch('eligibleParticipants/deleteEligibleParticipant', {
        organizationId: this.orgId,
        seriesId: this.$route.params.seriesSlug,
        eligibleParticipant: this.editedItem,
      });
      this.closeDelete();
    }
  }

  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });

    this.initialize();
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });

    this.initialize();
  }

  async save() {
    if (this.eps) {
      if (this.editedIndex > -1) {
        await this.$store.dispatch('eligibleParticipants/updateEligibleParticipant', {
          organizationId: this.orgId,
          seriesId: this.$route.params.seriesSlug,
          eligibleParticipant: this.editedItem,
        });
      } else {
        await this.$store.dispatch('eligibleParticipants/addEligibleParticipant', {
          organizationId: this.orgId,
          seriesId: this.$route.params.seriesSlug,
          eligibleParticipant: this.editedItem,
        });
      }
      this.close();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filterText(value: string, search: string | null, _: never) {
    return (
      value != null &&
      search != null &&
      typeof value === 'string' &&
      value
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) !== -1
    );
  }

  get user(): User | null {
    return this.$store.state.auth.user;
  }

  performEpDataValidation(results: ParseResult<EligibleParticipant>): EpParsingErrorResult {
    const parsingResult: EpParsingErrorResult = { headerError: false, duplicates: [], rows: [] };
    const track = new Map<string, number[]>();

    //Header checking
    if (results) {
      const correctHeaders = [
        'firstName',
        'lastName',
        'gender',
        'dateOfBirth',
        'startDate',
        'endDate',
      ];
      const parsedOutHeaders = results.meta.fields;
      if (parsedOutHeaders) {
        const cleanedUpParsedHeaders = parsedOutHeaders.map(header => header.trim());
        const missingHeaders = correctHeaders.filter(
          item => cleanedUpParsedHeaders.indexOf(item) < 0
        );
        if (missingHeaders.length > 0) {
          parsingResult.headerError = true;
          parsingResult.rows.push({
            index: 0,
            errors: [missingHeaders.join(', ')],
          });
          return parsingResult;
        }
      } else {
        parsingResult.headerError = true;
        parsingResult.rows.push({
          index: 0,
          errors: [correctHeaders.join(', ')],
        });
        return parsingResult;
      }
    }

    for (let index = 0; index < results.data.length; index++) {
      const {
        id,
        firstName,
        lastName,
        club,
        gender,
        dateOfBirth,
        startDate,
        endDate,
      } = results.data[index];
      const row: RowParsingError = { index: index + 1, errors: [] };

      // field data validation
      const genders = this.genders.map(gender => gender.toUpperCase());
      if (typeof firstName != 'string' || !firstName.length) {
        row.errors.push(`Invalid First Name (must not be a number)`);
      }
      if (typeof lastName != 'string' || !lastName.length) {
        row.errors.push(`Invalid Last Name (must not be a number)`);
      }
      if (typeof gender != 'string' || !genders.includes(gender.toUpperCase())) {
        row.errors.push(`Invalid Gender (must be Male or Female)`);
      }
      if (typeof dateOfBirth != 'string' || !moment(dateOfBirth).isValid()) {
        row.errors.push(`Invalid Date of Birth (must be mm/dd/yyyy)`);
      }
      if (typeof startDate != 'string' || !moment(startDate).isValid()) {
        row.errors.push(`Invalid Start Active Date (must be mm/dd/yyyy)`);
      }
      if (typeof endDate != 'string' || !moment(endDate).isValid()) {
        row.errors.push(`Invalid End Active Date (must be mm/dd/yyyy)`);
      }

      if (
        typeof firstName == 'string' &&
        typeof lastName == 'string' &&
        typeof gender == 'string' &&
        typeof dateOfBirth == 'string' &&
        typeof startDate == 'string' &&
        typeof endDate == 'string'
      ) {
        const key = `${firstName.trim()}-${lastName.trim()}-${gender.toUpperCase()}-${dateOfBirth}-${startDate}-${endDate}`;

        //duplicate checking
        if (track.has(key)) {
          track.get(key)?.push(index + 2);
        } else {
          track.set(key, [index + 2]);
        }
      }

      //adding the rows that have errors present
      if (row.errors.length) {
        parsingResult.rows.push(row);
      }

      const ep = { id, firstName, lastName, club, gender, dateOfBirth, startDate, endDate };
      results.data[index] = ep;
    }

    parsingResult.duplicates = Array.from(track.values()).filter(value => value.length > 1);
    return parsingResult;
  }

  submit() {
    try {
      this.uploading = true;
      if (!this.epFile) {
        throw Error('No file');
      }

      parse(this.epFile, {
        dynamicTyping: true,
        header: true,
        complete: async (results: ParseResult<EligibleParticipant>, epFile: File) => {
          try {
            const regex = new RegExp('.*.xlsx');
            if (regex.test(epFile.name)) {
              let workbook: xlsx.WorkBook;
              await epFile.arrayBuffer().then(value => {
                workbook = xlsx.read(value);
                results.data = xlsx.utils.sheet_to_json<EligibleParticipant>(
                  workbook.Sheets[workbook.SheetNames[0]],
                  { raw: false }
                );
                results.errors = [];
                results.meta.fields = Object.keys(results.data[0]);
              });
            }

            this.failedParse = this.performEpDataValidation(results);

            if (!this.failedParse.rows.length && !this.failedParse.duplicates.length) {
              const epGroup: EpUploadDto = {
                organizationId: this.orgId,
                seriesId: this.$route.params.seriesSlug,
                eligibleParticipants: (results.data as EligibleParticipant[]).map(ep => {
                  ep.dateOfBirth = new Date(ep.dateOfBirth).toISOString().substr(0, 10);
                  ep.startDate = new Date(ep.startDate).toISOString().substr(0, 10);
                  ep.endDate = new Date(ep.endDate).toISOString().substr(0, 10);
                  return ep;
                }),
              };

              const uploadHistory: UploadHistory = {
                filename: epFile.name,
                timestamp: new Date().toISOString().substr(0, 10),
              };

              await this.$store.dispatch(
                'eligibleParticipants/uploadEligibleParticipants',
                epGroup
              );
              await this.$store.dispatch('eligibleParticipants/updateUploadHistory', {
                organizationId: this.orgId,
                seriesId: this.$route.params.seriesSlug,
                uploadHistory: uploadHistory,
              });

              this.series = this.seriesDetails(this.$route.params.seriesSlug);
              this.epFile = null;
              this.initialize();
            }
          } catch (error) {
            this.$store.dispatch('errorModal/showDialog', {
              title: 'Error Uploading Eligible Participants',
              message: error.message,
            });
          } finally {
            this.uploading = false;
          }
        },
      });
    } catch (error) {
      console.error(error);
      this.uploading = false;
    }
  }
}
